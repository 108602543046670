import React, { useState, useEffect } from "react";
// import Navbar2 from "./navbar2.jsx";
// import accountIcon from "../asset/icons/account.svg";
// import settingsIcon from "../asset/icons/settings.svg";
// import searchImg from "../asset/search.svg";
// import docs1 from "../asset/icons/doc.svg";
// import reloadIcon from "../asset/icons/reload.svg";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import apiIcon from "../asset/icons/api-connected.svg";
// import profileIcon from "../asset/icons/user.svg";
// import editIcon from "../asset/icons/Layer_1.svg";
// import APIIcon from "../asset/icons/API.svg";
// import auth from "../services/authService.js";
// import reportIcon from "../asset/icons/report.svg";
import reportIcon from "../../asset/icons/report.svg"
import agreementsIcon from "../../asset/icons/agreements.svg";
// import http from "../services/httpService.js";
// import UTCPdf from "../asset/Users T & C Dispute Paper.pdf";
import { Link } from "react-router-dom";
// import Loading from "./loading.jsx";
import CryptoJS from "crypto-js";

import http from "../../services/httpService";
import auth from "../../services/authService";
import LoginHeader from "../../components/loginHeader";


function NewUserDashboard() {
  const email = auth.getUser().email;
  const [agreementData, setAgreementData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [search, setSearch] = useState("");
  const [exist, setExist] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // const generateAndDownloadPDF = async () => {
  //   const existingPdfBytes = await fetch(UTCPdf).then((res) =>
  //     res.arrayBuffer()
  //   );
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   // const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  //   // const pages = pdfDoc.getPages();

  //   // const pdfBytes1 = await pdfDoc.saveAsBase64();
  //   const pdfBytes = await pdfDoc.save();
  //   const blob = new Blob([pdfBytes], { type: "application/pdf" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "Users T & C Dispute Paper.pdf";
  //   link.click();
  // };

  const fetchData2 = async () => {
    try {
      let response = await http.post2("/getUserExist", { email });
      setExist({ exists: response.data });
      console.log(response.data, "userExists");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async () => {
    try {
      let response = await http.post2("/getAgreement", {
        page: 1,
        pageSize: 5,
        email: auth.getUser().email,
        search: search,
      });
      console.log(response.data.data);
      setAgreementData(response.data.data);
      // setData(data1.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        console.log(response.data, "response.data gstComponent");

        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      let response = await http.post2("/getAgreement", {
        page: 1,
        pageSize: 5,
        email: auth.getUser().email,
      });
      console.log(response.data.data);
      if(response.status!=400){
        setAgreementData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyNBFCClient =async ()=>{
    try {
      let response = await http.post2("/verifyNBFCClient", {
        mid:auth.getUser().UserLogin._id
      });
      console.log(auth.getUser().UserLogin._id);
      console.log(response.data, "data.data");
      if(response.status==200){
        // const url=`http://localhost:3002/verify?token=${response.data.token}&reload=true`
        const url=`https://verify.pvtcourt.com/verify?token=${response.data.token}&reload=true`
        window.open(url, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleYourProfile =async ()=>{
    try {
      let response = await http.post2("/UpdateUserDataInS3", {
        did:auth.getUser().UserLogin._id
      });
      if(response.data=="Json Creation Successfully" || response.data=="Already Created"){
        const id=auth.getUser().UserLogin._id;
        const secretKey = 'PrivatecourtProfileData';
        const uid=auth?.getUser()?.UserLogin._id; 
        const encryptedData = CryptoJS.AES.encrypt(uid, secretKey).toString();
        // console.log(response.data, "data.data");
      if(uid){
        const url=`https://profile.pvtcourt.com/profile?uid=${encodeURIComponent(encryptedData)}`
        window.open(url, '_blank');
      }
      }

    } catch (error) {
      console.log(error);
    }
  }

  const fetchData4 = async () => {
    try {
      let response = await http.post2("/getInvoices", {
        page: 1,
        perPage: 5,
        email: auth.getUser().email,
      });
      console.log(response.data);
      if(response.status!=400){
        setInvoiceData(response.data);
      }
      // setData(data1.data);
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    fetchData3();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // console.log(billData, "billData");
    fetchData();
    fetchData2();
    fetchData4();
  }, [search.length == 0]);

  return (
    // isLoading? <Loading></Loading>:
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              {/* {<Navbar2 />} */}
              <h2 className="admin-name">
                {/* {companyName ? companyName : email} */}
              </h2>
              {/* <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link> */}
              {/* <button type="button" className="btn btn-light btn-sm back-btn mb-3"><i className="bi bi-arrow-left"></i> Back</button> */}
              {/* <h3 className="small-des">No upcoming meeting today.</h3> */}
              {/* <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                Menu
            </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg"> 
                <h2 className="d-lg-block d-none">Notifications</h2>
                <div className="alert alert-primary bg-transparent" role="alert">
                  LRN successfully <strong>generated</strong> and <strong>posted</strong>!
                </div>
                  {/* <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No new notifications
                  </div>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No new notifications
                  </div> */}
                  <div className="row row-cols-2">
                  <div className="col">
                          {/* <Link to={"/notice"} className="white-circle"> */}
                          <div className="white-circle">
                            <img
                              src={agreementsIcon}
                              alt=""
                              style={{ display: "block", margin: "0 auto" }}
                            />
                            <h2>Notice details</h2>
                          </div>
                            
                          {/* </Link> */}
                        </div>
                    <div className="col">
                      {/* <Link to="/reporting" className="white-circle"> */}
                      <div className="white-circle">
                        <img
                          src={reportIcon}
                          style={{ display: "block", margin: "0 auto" }}
                          alt=""
                        />
                        <h2>Reports</h2>
                      </div>

                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <LoginHeader/>
            <div className="right-wrapper mt-2">
              <div className="row">
                <div className="col-xl-4 col-lg-12">
                  {search || agreementData.length > 0 ? (
                    <div className="card card-new update-status-card w-100">
                      <h2>Dispute Resolution Agreements</h2>
                      <form action="#" className="form-search mb-3 d-flex">
                        <input
                          type="text"
                          className="form-control form-control-new border-right-0"
                          placeholder="Search Agreement"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <img
                          // src={searchImg}
                          onClick={handleSearch}
                          className="ms-4"
                        />
                      </form>
                      {agreementData.length > 0 &&
                        agreementData.map((agreement, index) => {
                          return (
                            <div className="update-status-list d-flex align-items-center mb-3">
                              <div className="c-pic me-md-3 me-1">
                                <img className="shadow" 
                                // src={docs1}
                                alt="" />
                              </div>
                              <div className="c-content">
                                <h2>{agreement.Agreement.title}</h2>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic example"
                                >
                                  <button type="button" className="btn">
                                    Ref. No.
                                  </button>
                                  <button type="button" className="btn">
                                    {agreement.Agreement.referenceAgreementNo}
                                  </button>
                                </div>
                              </div>
                              <div className="resolved ms-auto">
                                <p className="mb-0">Fee</p>
                                <p>
                                  <strong>
                                    Rs{" "}
                                    {agreement.Agreement.Fee
                                      ? agreement.Agreement.Fee
                                      : ""}
                                  </strong>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      <div className="text-center mt-2">
                        {/* <Link
                          to="/usersDashboard"
                          className="btn btn-outline-primary"
                        >
                          view all <strong>Agreement</strong>
                        </Link> */}
                        <a className="btn btn-outline-primary">view all <strong>Agreement</strong></a>

                      </div>
                    </div>
                  ) : (
                    <div className="card card-new update-status-card">
                      <h2>Buy Dispute Resolution Agreement</h2>
                      <p>
                        Effortlessly create and download a comprehensive Dispute
                        Resolution Agreement.
                        <br />
                        <br /> Attach it to invoices, work orders, purchase
                        orders, and contracts, ensuring a structured process for
                        resolution in the event of disputes.
                        <br />
                        <br /> This agreement is crucial for producing necessary
                        documentation should a dispute arise, prioritizing
                        operational clarity and legal security for your business
                        operations.
                      </p>
                      <div className="text-center mt-2">
                        <Link
                          // to="/dispute"
                          className="btn btn-outline-primary"
                        >
                          Buy Now
                        </Link>
                        {/* <a className="btn btn-outline-primary">Buy Now</a> */}

                      </div>
                    </div>
                  )}

                  <div className="card card-new update-status-card w-100">
                    <h2>Add your first dispute</h2>
                    <p>
                      This form is your gateway to a structured dispute
                      submission.
                      <br />
                      <br /> To submit your dispute, follow these instructions
                      carefully. Our user-friendly form will guide you through
                      the process, ensuring your dispute is raised correctly.
                      For the best results, provide accurate and detailed
                      information.<br></br>
                      <br />
                      Let's work together towards a successful resolution.
                    </p>
                    <div className="text-center mt-2">
                      <a href="#" className="btn btn-outline-primary">
                        File a Dispute
                      </a>
                    </div>
                  </div>
                  <div className="card card-new update-status-card api-connection">
                    <h2 className="mb-0">Client Verification</h2><br></br>
                    <p>
                      To proceed ahead, it is essential to thoroughly verify the applicant’s details. Please complete the verification process using the provided links:
                      <br></br><br></br>
                      <ul>
                        <li>PAN Verification</li>
                        <li>Aadhaar Verification</li>
                        <li>Mobile Number Verificatio</li>
                        <li>Bank Details Verification</li>
                      </ul>
                       Completing these verifications will help make an informed decision and maintain the integrity of the process. 
                    </p>
                    <div className="flex row">
                      <br></br>
                      <br></br>
                      <div className="text-center mt-2">
                        <Link
                          to="/verifyClient"
                          className="btn btn-outline-primary"
                        >
                          Verify
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12">
                  <div className="card card-new update-status-card mb-3">
                    <h2>Do You know ?</h2>
                    <div className="d-inline-flex align-items-center">
                      <img 
                      // src={APIIcon} 
                      alt="" className="me-2" />
                      <h5 className="ms-2 mt-2 secondary-text-emphasis">API</h5>
                    </div>
                    <br></br>
                    <p>
                      You can impose the agreement terms on your website using
                      our API Connection.<br></br> <br></br> Its very easy. Just
                      generate the keys and define the terms. Select the text
                      field where you wish the terms to be displayed.<br></br>{" "}
                      <br></br> Your customer may see the terms with the
                      Agreeable Check box.
                    </p>
                    <div className="text-center mt-2">
                      <a href="#" className="btn btn-outline-primary">
                        Full documentation here
                      </a>
                    </div>
                  </div>
                  {exist ? (
                    <>
                      {invoiceData.length == 0 && (
                        <div className="card card-new update-status-card mb-3">
                          <h2>Billing</h2>
                          <p>Discover the simplicity of our monthly postpaid billing system.<br></br><br></br>
                              All invoices are meticulously generated on the 1st of each month, ensuring a 
                              transparent and efficient financial process. <br></br><br></br>
                              Trust us for a seamless billing experience that allows you to focus on what truly matters.
                          </p>

                        <div className="text-center mt-2">
                            {/* <Link
                            to="/billingInformation"
                            className="btn btn-outline-primary"
                          >
                          view all <strong>Bills</strong>
                          </Link> */}
                          <a className="btn btn-outline-primary">view all <strong>Bills</strong></a>

                      </div>
                        </div>
                      )}
                      {( invoiceData.length > 0) && (
                        <div className="card card-new update-status-card w-100">
                          <h2>Billing</h2>
                          {/* <form action="#" className="form-search mb-3 d-flex">
                        <input
                          type="text"
                          className="form-control form-control-new border-right-0"
                          placeholder="Search Bill"
                          value={search}
                          onChange={(e)=>setSearch(e.target.value)}
                        />
                        <img src={searchImg} onClick={handleSearch} className='ms-4'/>
                    </form> */}
                          {invoiceData.length > 0 &&
                            invoiceData.map((bill, index) => {
                              return (
                                <div className="update-status-list d-flex align-items-center mb-3">
                                  <div className="c-pic me-md-3 me-1">
                                    <img
                                      className="shadow"
                                      // src={docs1}
                                      alt=""
                                    />
                                  </div>
                                  <div className="c-content">
                                    {/* <h2>{bill.Agreement.title}</h2> */}
                                    <div
                                      className="btn-group"
                                      role="group"
                                      aria-label="Basic example"
                                    >
                                      <button type="button" className="btn">
                                        InvoiceID
                                      </button>
                                      <button type="button" className="btn">
                                        {bill.invoiceId}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="resolved ms-auto">
                                    <p className="mb-0">Fee</p>
                                    <p>
                                      <strong>Rs {bill.totalFee}</strong>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="text-center mt-2">
                            {/* <Link
                              to="/billingInformation"
                              className="btn btn-outline-primary"
                            >
                              view all <strong>Bills</strong>
                            </Link> */}
                            <a className="btn btn-outline-primary">view all <strong>Bills</strong></a>

                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="card card-new update-status-card mb-3">
                      <h2>Billing</h2>
                      <p>Discover the simplicity of our monthly postpaid billing system.<br></br><br></br>
                          All invoices are meticulously generated on the 1st of each month, ensuring a 
                          ransparent and efficient financial process. <br></br><br></br>
                          Trust us for a seamless billing experience that allows you to focus on what truly matters.
                          
                      </p> 
                      <div className="text-center mt-2">
                        {/* <Link
                          to="/billingInformation"
                          className="btn btn-outline-primary"
                        >
                          view all <strong>Bills</strong>
                        </Link> */}
                        <a className="btn btn-outline-primary">view all <strong>Bills</strong></a>
                      </div>
                    </div>
                  )}
                  {/* <div className="card card-new update-status-card api-connection">
                    <h2 className="mb-0">Company Verification</h2><br></br>
                    <p>
                      To proceed ahead, it is essential to thoroughly verify the applicant’s details. Please complete the verification process using the provided links:
                      <br></br><br></br>
                      <ul>
                        <li>Company PAN Verification</li>
                        <li>Director's PAN</li>
                        <li>Aadhaar Verification</li>
                        <li>Mobile Number Verificatio</li>
                        <li>Bank Details Verification</li>
                      </ul>
                       Completing these verifications will help make an informed decision and maintain the integrity of the process. 
                    </p>
                    <div className="flex row">
                      
                      <br></br>
                      <br></br>
                      <div className="text-center mt-2">
                        <Link
                          to="/verifyCompanyClient"
                          className="btn btn-outline-primary"
                        >
                          Verify
                        </Link>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-4 col-lg-12">
                  <div className="card card-new update-status-card api-connection">
                    <h2 className="mb-0">Your Profile</h2><br></br>
                    <p>
                    To access the full potential of our application, users are required to
                     complete a thorough verification. <br></br><br></br> This includes providing essential details
                      such as GST, PAN, CIN, and bank information.<br></br> <br></br>
                      Rest assured, as a legally compliant entity, we utilize Government Website APIs
                       to validate additional details, prioritizing security and regulatory adherence throughout 
                       the verification process.
                    </p>
                    <div className="flex row">
                      <div className="text-center mt-2">
                        <button 
                        className="btn btn-outline-primary"
                        onClick={handleYourProfile}
                        >
                         <i className="bi bi-download-solid"></i>Your profile
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card card-new update-status-card api-connection">
                    <h2 className="mb-0">Terms & Conditions</h2><br></br>
                    <p>
                      By accessing PrivateCourt services, users agree to comply with the platform's 
                      terms and conditions. Users must provide accurate information.<br></br><br></br>
                      PrivateCourt reserves the right to verify details for legal compliance. 
                      Unauthorized account use or sharing of credentials is strictly prohibited. <br></br><br></br>
                      Privacy and data security are prioritized. Violation of terms may result in account suspension
                      or termination. Prompt reporting of suspicious activities is encouraged.
                    </p>
                    <div className="flex row">
                      <br></br>
                      <br></br>
                      <div className="text-center mt-2">
                        <button 
                        className="btn btn-outline-primary"
                        // onClick={generateAndDownloadPDF}
                        >
                         <i className="bi bi-download-solid"></i>  Download
                        </button>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewUserDashboard;
