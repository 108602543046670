// import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import http from "../../services/httpService";
import auth from "../../services/authService";
import { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import reportIcon from "../../asset/icons/report.svg"
import agreementsIcon from "../../asset/icons/agreements.svg";
// import Loading from "./loading";

const NBFCCompanyClientVerify = (props) => {
  const email = auth.getUser().email;
  const [noticeInfos, setNoticeInfo] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);


  const fetchData3 = async () => {
    try {
      console.log(auth.getUser().UserLogin._id);
      const response = await http.post2("/getCompanyClientsInfo", {
        mid:auth.getUser().UserLogin._id,
        page,
        perPage,
      });
      if (response.status != 400) {
        setNoticeInfo(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  // const copyToClipboard = (id) => {
    
  //   const secretKey = 'PrivatecourtProfileData';
  //   const uid=id; 
  //   const encryptedData = CryptoJS.AES.encrypt(uid, secretKey).toString();
  //   const profileLink = `http://localhost:3002/UserProfile?data=${encodeURIComponent(encryptedData)}`;
  //   navigator.clipboard.writeText(profileLink).then(() => {
  //     alert('Profile URL copied to clipboard!');
  //   }).catch((err) => {
  //     console.error('Failed to copy: ', err);
  //   });
  // };

  const handleYourProfile =async (id)=>{
    try {
      let response = await http.post2("/nbfcUpdateUserDataInS3", {
        did:id
      });
      console.log(response.data);
      if(response.data=="Json Creation Successfully" || response.data=="Already Created"){
        console.log(response.data);
        // const id=auth.getUser().UserLogin._id;
        const secretKey = 'PrivatecourtProfileData';
        const uid=id;
        const encryptedData = CryptoJS.AES.encrypt(uid, secretKey).toString();
        // console.log(response.data, "data.data");
        if(uid){
          const url=`https://profile.pvtcourt.com/profile?uid=${encodeURIComponent(encryptedData)}`
          window.open(url, '_blank');
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  const verifyNBFCClient =async ()=>{
    try {
      let response = await http.post2("/verifyCompanyNBFCClient", {
        mid:auth.getUser().UserLogin._id
      });
      console.log(auth.getUser().UserLogin._id);
      console.log(response.data, "data.data");
      if(response.status==200){
        const url=`http://localhost:3002/verifyCompany?token=${response.data.token}&reload=true`
        // const url=`https://verify.pvtcourt.com/verifyCompany?token=${response.data.token}&reload=true`
        window.open(url, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData3();
  }, [page, perPage]);

  return (<div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              {/* <Navbar2 /> */}
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              {/* <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No notification
                  </div>

                </div>
              </div> */}

              <div className="" id="collapseExample">
                <div className="dot-bg"> 
                {/* <h2 className="d-lg-block d-none">Notifications</h2>
                <div className="alert alert-primary bg-transparent" role="alert">
                  LRN successfully <strong>generated</strong> and <strong>posted</strong>!
                </div> */}
                  
                  <div className="row row-cols-2">
                  <div className="col">
                          {/* <Link to={"/notice"} className="white-circle"> */}
                          <span className="white-circle">
                            <img
                              src={agreementsIcon}
                              alt=""
                              style={{ display: "block", margin: "0 auto" }}
                            />
                            <h2>Notice details</h2>
                          </span>
                          {/* </Link> */}
                        </div>
                    <div className="col">
                      {/* <Link to="/reporting" className="white-circle"> */}
                      <span className="white-circle">
                        <img
                          src={reportIcon}
                          style={{ display: "block", margin: "0 auto" }}
                          onClick={verifyNBFCClient}
                          alt=""
                        />
                        <h2>Verify</h2>
                      </span>
                        
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">Verified Company Client</h2>
                      <div className="org-data nbfc-table mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr> 
                              <th scope="col">Full Name</th>
                              <th scope='col'>Email</th>
                              <th scope='col'>Status</th>
                              <th scope='col'>Stage</th>
                              <th scope='col'>Profile</th>
                              <th scope='col'>Complete Verification</th>
                            </tr>
                          </thead>
                          <tbody>
                            {noticeInfos.length>0 ? (
                              <>
                                {noticeInfos.map((item, index) => {
                                  return (
                                    <tr key={item._id}>
                                      <td data-label="Full Name">{item.full_name}</td>
                                      <td data-label='Email'>{item.email}</td>
                                      <td data-label="Status">{item.accountStatus}</td>
                                      <td data-label="Stage">{item.verificationStage}</td>
                                      <td data-label="Profile">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={()=>handleYourProfile(item._id)}
                                      >
                                        <i className="bi bi-person-circle text-white"></i>
                                      </button>
                                      </td>
                                      <td>
                                        <button 
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={item.verificationStage=="done"}
                                        >
                                          <i className="bi bi-Square text-white">Complete</i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                        <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div className="data-pagi mt-lg-0 mt-2">
                            <select
                              className="form-control"
                              id="floatingSelect"
                              value={perPage ? perPage : 10}
                              onChange={(e) => setPerPage(e.target.value)}
                              aria-label="Floating label select example"
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <div
                              className="btn-toolbar d-flex flex-row"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="First group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => setPage(+page - 1)}
                                  disabled={page <= 1}
                                >
                                  <i className="bi bi-chevron-left text-white"></i>
                                </button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Second group"
                              >
                                <button
                                  type="button"
                                  className="btn text-primary"
                                >
                                  {page}
                                </button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={noticeInfos.length < +perPage}
                                  onClick={() => setPage(+page + 1)}
                                >
                                  <i className="bi bi-chevron-right text-white"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-4"></div> */}
                      </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  
};
export default NBFCCompanyClientVerify;


