// import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import http from "../../services/httpService";
import { useState, useEffect } from "react";
// import Loading from "./loading"

const AdminPanelNBCF = (props) => {
  const email = auth.getUser().email;
  const [noticeInfos, setNoticeInfo] = useState([]);
  const [selNotice, setSelNotice] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const convertMillisecondToDate = (timeStamp) => {
    const date = new Date(timeStamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handlechange5=()=>{
    if(!selNotice.length){
        let arr=noticeInfos.map(n=>n._id);
        setSelNotice(arr);
    }else setSelNotice([]);
  }

  const handleChange=(_id)=>{
    if(selNotice.includes(_id)){
        let arr=selNotice.filter(n=>n!=_id);
        setSelNotice(arr);
    }
    else setSelNotice((prev)=>[...prev, _id]);
  }

  const handleApprove=async(event)=>{
    event.preventDefault();
    try {
        const response = await http.post('/setApproveCsv', {selNotice});
        if (response.status != 400) {
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  const handleBulkApprove=async(event)=>{
    event.preventDefault();
    try {
        const response = await http.post('/setBulkApproveCsv', {selNotice});
        if (response.status != 400) {
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  const handleEmailer=async(email1, timeStamp1, letterhead)=>{
    try{
        const response = await http.post2('/sendEmailNotice', {email:email1, timeStamp:timeStamp1, letterHead:letterhead});
        if(response.status!=400){
            console.log(response.data);
        }
    } catch (error) {
        console.error("Error fetching Data:", error);
    }
  }

  const handleChange6=async(value, timeStamp, email2 )=>{
    console.log(value, email2, timeStamp);
    try {
      const response = await http.post("/postNoticeInfo", {
        email:email2,
        timeStamp,
        letterhead:value
      });
      // console.log(response.data)
      if (response.status != 400) {
        fetchData3();
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error posting letterhead:", error);
    }
  }

  const handleChange7=async(value, timeStamp, email2)=>{
    console.log(value, email2, timeStamp);
    try {
      const response = await http.post("/postNoticeType", {
        email:email2,
        timeStamp,
        textType:value
      });
      if (response.status != 400) {
        fetchData3();
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error posting letterhead:", error);
    }
  }

  const handlePhoneMap=async( timeStamp, email2)=>{
    console.log(email2, timeStamp);
    alert("phone mapping start");
    try {
      const response = await http.post2("/phoneMappingNBFCDatas", {
        email:email2,
        timeStamp
      });
      if (response.status != 400) {
        alert("phone mapping done");
        console.log(response.data);
      }
    } catch (error) {
      alert("phone mapping start");
      console.error("Error posting letterhead:", error);
    }
  }


  const fetchData3 = async () => {
    try {
      const response = await http.post("/getAdminNoticeInfo", {
        email,
        page,
        perPage,
      });
      if (response.status != 400) {
        setNoticeInfo(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData2();
  }, []);

  useEffect(() => {
    fetchData3();
  }, [page, perPage]);

  return (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              {/* <Navbar2 /> */}
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No notification
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">Admin Panel</h2>
                      <div className="org-data nbfc-table mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckChecked"
                                      checked={
                                        noticeInfos.length == selNotice.length
                                      }
                                      onChange={handlechange5}
                                    />
                                  </div>
                                </th>
                              <th scope="col">Action Date</th>
                              <th scope='col'>Email</th>
                              {/* <th scope='col'>Action type</th>
                              <th scope='col'>Action</th> */}
                              <th scope='col'>Text type</th>
                              <th scope="col">Letterhead</th>
                              <th scope="col">Single Approve</th>
                              <th scope="col">Bulk Approve</th>
                              <th scope="col">Phone Map</th>
                              <th scope="col">View details</th>
                              <th scope="col">Send email</th>
                              {/* <th scope="col"></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {noticeInfos.length>0 ? (
                              <>
                                {noticeInfos.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={selNotice.includes(
                                                item._id + ""
                                              )}
                                              onChange={()=>handleChange(item._id)}
                                              id={"checkbox_" + item._id}
                                              value={item._id + ""}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={"checkbox_" + item._id}
                                            >
                                              {/* Add your label text here if needed */}
                                            </label>
                                          </div>
                                        </td>
                                      <td data-label="Action Date">{convertMillisecondToDate(item.timeStamp)}</td>
                                      <td data-label='Email'>{item.email}</td>
                                      {/* <td data-label="Action type">{item.actionType}</td>
                                      <td data-label="Action">{item.action}</td> */}
                                      <td data-label="Text type">
                                          <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={item.textType}
                                            // value="Joylin Rego, Mumbai"
                                            onChange={(e)=>handleChange7(e.target.value, item.timeStamp, item.email)}
                                            // onClick={(e) => setLetterhead(e.target.value)}
                                          >
                                            <option value="">Select Text</option>
                                            <option value="LRN">LRN</option>
                                            <option value="Conciliation">Conciliation</option>
                                            <option value="Arbitration">Arbitration</option>
                                            <option value="Invocation">Invocation</option>
                                            <option value="Acceptance">Acceptance</option>
                                            <option value="Hearing 1">Hearing 1</option>
                                            <option value="Hearing 2">Hearing 2</option>
                                            <option value="Hearing 3">Hearing 3</option>
                                          </select>
                                      </td>
                                      <td data-label="Letterhead">
                                        <select
                                          className="form-select"
                                          aria-label="Default select example"
                                          value={item.letterhead}
                                          // value="Joylin Rego, Mumbai"
                                          onChange={(e)=>handleChange6(e.target.value, item.timeStamp, item.email)}
                                          // onClick={(e) => setLetterhead(e.target.value)}
                                        >
                                          <option value="">Select Letterhead</option>
                                          <option value="Joylin Rego, Mumbai">Joylin Rego, Mumbai</option>
                                          <option value="New Delhi">New Delhi</option>
                                          <option value="Delhi">Delhi</option>
                                          <option value="Bangalore">Bangalore</option>
                                          <option value="Ambernath">Ambernath</option>
                                          <option value="Hyderabad">Hyderabad</option>
                                          <option value="Thane">Thane</option>
                                          <option value="Gurugaon">Gurugaon</option>
                                        </select>
                                      </td>
                                      <td data-label="Single Approve">{item.approval}</td>
                                      <td data-label='Bulk Approve'>{item.bulkApproval}</td>
                                      <td data-label="Phone Map"><button className="btn btn-primary btn-sm" onClick={(e)=>handlePhoneMap(item.timeStamp, item.email)}>Map</button></td>
                                      {/* <td data-label="View details">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          data-bs-toggle="modal"
                                          data-bs-target="#updates-1"
                                        >
                                          <i className="bi bi-eye text-white"></i>
                                        </button>
                                      </td> */}
                                      <td data-label="View More Deatils">
                                        <button type="button" className="btn btn-primary"
                                        onClick={()=>{(props.history.push(`/noticeDetail/${item.timeStamp}`))}}>
                                        <i className="bi bi-eye text-white"></i>
                                        </button>
                                      </td>
                                      <td data-label="Send email">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={item.approval=='No'}
                                          onClick={()=>handleEmailer(item.email, item.timeStamp, item.letterhead)}
                                        >
                                          <i className="bi bi-envelope-fill text-white"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-lg-3">
                            <button className="btn btn-primary" disabled={selNotice.length==0} onClick={handleApprove}>
                                Approve Selected
                            </button>
                          </div>
                          <div className="col-lg-3">
                            <button className="btn btn-primary" disabled={selNotice.length==0} onClick={handleBulkApprove}>
                                Bulk Arrove Selected 
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                              <select
                                className="form-control"
                                id="floatingSelect"
                                value={perPage ? perPage : 10}
                                onChange={(e) => setPerPage(e.target.value)}
                                aria-label="Floating label select example"
                              >
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <div
                                className="btn-toolbar"
                                role="toolbar"
                                aria-label="Toolbar with button groups"
                              >
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPage(+page - 1)}
                                    disabled={page <= 1}
                                  >
                                    <i className="bi bi-chevron-left text-white"></i>
                                  </button>
                                </div>
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="Second group"
                                >
                                  <button
                                    type="button"
                                    className="btn text-primary"
                                  >
                                    {page}
                                  </button>
                                </div>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Third group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={noticeInfos.length < +perPage}
                                    onClick={() => setPage(+page + 1)}
                                  >
                                    <i className="bi bi-chevron-right text-white"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminPanelNBCF;