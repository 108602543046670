import React, { useRef, useState, useEffect} from "react";
import bgLogo from "./../../../images/logo-bg.png";
import bgLogoWhite from "./../../../images/logo-white-bg.png";
import { useSelector, useDispatch } from "react-redux";
import VerifyButton from "./verifyButton";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import DataCompilation from "./dataCompilation";


const getGreeting = () => {
  const currentDate = new Date();
  const utcOffset = currentDate.getTimezoneOffset() * 60000;
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(currentDate.getTime() + utcOffset + istOffset);
  const hours = istDate.getHours();

  if (hours >= 5 && hours < 12) {
    return 'Good Morning';
  } else if (hours >= 12 && hours < 17) {
    return 'Good Afternoon';
  } else if (hours >= 17 && hours < 21) {
    return 'Good Evening';
  } else {
    return 'Good Night';
  }
};
const MidEmailOTP = ({ children }) => {
  // const theme = useSelector((state) => state.theme.theme);
  const email = useSelector((state) => state.email.email);
  const userName = useSelector((state) => state.userName.userName);
  const [dataCompilation, setDataCompilation]=useState(false);
  const [disabled, setDisabled] = useState(true);
  const [otpEr, setOtpEr] = useState(false);
  const [greeting, setGreeting] = useState(getGreeting());
  const dispatch = useDispatch();
  const translate = useLanguage.getLang();
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleKeyDown = (index, e) => {
    setOtpEr(false);
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      const prevIndex = index-1;
      inputRefs[prevIndex].current.focus();
    }
    else if(/^[A-Z]$/i.test(e.key)){
      inputRefs[index].current.value = "";
    }
    else if (e.key >= "0" && e.key <= "9") {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = e.key;
        inputRefs[nextIndex].current.focus();
      }
    }
    else if (
      index === 0 &&
      e.key !== "Backspace" &&
      (e.key < "0" || e.key > "9")
    ) {
      inputRefs[0] = e.key;
      e.preventDefault();
    }

    let otp1=inputRefs.map((ref) => ref.current.value).join("")
    if(otp1.length==6){
      setDisabled(false);
    }
    else setDisabled(true);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    http
      .post2("/loginUser", {
        email,
      })
      .then((response) => {
        console.log("Response:", response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async() => {
    let otp=inputRefs.map(ref=>ref.current.value).join("");
    console.log(otp);
    setDataCompilation(true)
    try {
      const response = await http.post2("/signinUser", {
        email,
        otp,
      });
      if(response.status==200){
        auth.login(response.data.token);
        const [header, payload, signature] = response.data.token.split(".");
        const user = JSON.parse(atob(payload)).UserLogin;
        console.log(user, "user email Otp");
        setDataCompilation(false);
        if( user?.verificationStage=="done"){
          console.log('go to dashboard');
          try {
            let token=auth.getToken();
            // const url=`http://localhost:3002?token=${token}`
            const url=`https://dashboard.pvtcourt.com/dashboard?token=${token}`
            auth.logout();
            window.location.href = url;
            // http://localhost:3002/dashboard?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyTG9naW4iOnsiX2lkIjoiNjY4NTBhNzQ5YmRhYzJiNjZiMmFjMmQ5IiwiZW1haWwiOiJiYWxyYW1AcHJpdmF0ZWNvdXJ0LmluIiwiY2luQXJyYXkiOltdLCJvdHAiOjk5Nzg3Niwibmlja05hbWUiOiIiLCJjb21wYW55TmFtZSI6IiIsInBlcnNvbmFsTmFtZSI6IiIsInBhbl9udW1iZXIiOiIiLCJwYW5DYXRlZ29yeSI6IiIsImdzdGluQXJyYXkiOltdLCJnc3RQYW5BcnJheSI6W10sImdzdENpbiI6W10sImFjY291bnRTdGF0dXMiOiJ1cGRhdGUiLCJ3cm9uZ0F0dGVtcHRzIjowLCJwYW5QZXJzb25GdWxsTmFtZSI6W10sInZlcmlmaWNhdGlvblN0YWdlIjoiZG9uZSIsImdzdFBhbiI6W10sIm1pZEFycmF5IjpbXSwiX192IjowLCJmdWxsX25hbWUiOiIgICIsInRvdGFsUGF5bWVudCI6IiJ9LCJpYXQiOjE3MjcwOTQ2Njl9.cBXmh4MD_vg068IgHB3ZnGL-QpKLBYj064acehLTgG0
          }
          catch (error) {
            console.log(error);
          }
        }
        else if(user.verificationStage ){
          setDataCompilation(false);
          console.log(user);
          dispatch(changeStage(user.verificationStage));
        }
        else{
          setDataCompilation(false);
          dispatch(changeStage("pan"));
        }
      }
    } catch (error) {
      setOtpEr(true);
      setDataCompilation(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <>{
      dataCompilation ?<>
        <DataCompilation/>
      </>:
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 login-mid-left">
            <h2>
              <span data-translate="tran-1">
                {translate?.[greeting] ?? greeting}
              </span>{" "}
              {userName}
            </h2>
            <p data-translate="tran-2">
              {translate?.["Account security starts with verification"] ??
                "Account security starts with verification"}
            </p>
          </div>
          {/* <!-- /.col-lg-6 --> */}
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="card pb-normal">
              <div className="vf-content">
                <h1 data-translate="tran-3">
                  {translate?.["OTP Validation for Account Access"] ??
                    "OTP Validation for Account Access"}
                </h1>
                <p data-translate="tran-4">
                  {translate?.[
                    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process."
                  ] ??
                    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process."}
                </p>
                <p data-translate="tran-7">
                  {translate?.[
                    "For any queries or assistance, feel free to call us at +91-9699900111."
                  ] ??
                    "For any queries or assistance, feel free to call us at +91-9699900111."}
                </p>
                <p>
                  <strong data-translate="tran-8">
                    {translate?.[
                      "Thank you for entrusting PrivateCourt. Your security is our priority."
                    ] ??
                      "Thank you for entrusting PrivateCourt. Your security is our priority."}
                  </strong>
                </p>
              </div>
              <div className="otp-boxs d-flex flex-row justify-content-center">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <input
                    key={index}
                    className="text-center form-control rounded"
                    onKeyUp={(e) => handleKeyDown(index, e)}
                    type="text"
                    id={`digit-${index}`}
                    maxLength="1"
                    ref={inputRefs[index]}
                  />
                ))}
              </div>
              <p className="text-end mb-0 pt-2">
                <span data-translate="tran-5">
                  {translate?.["Didn't receive OTP?"] ?? "Didn't receive OTP?"}
                </span>{" "}
                <strong className="pe-auto" data-translate="tran-6" onClick={handleSubmit2}>
                  {translate?.["Request a Resend"] ?? "Request a Resend"}
                </strong>
              </p>
              {
                otpEr && <div class="alert alert-danger vfe-content" role="alert">
                    <div class="text-center">
                      <i class="bi bi-exclamation-triangle-fill text-danger"></i>
                    </div>
                    <h4 class="alert-heading" data-translate="tran-10">{translate?.["Error: Incorrect OTP"]??"Error: Incorrect OTP"}</h4>
                    <p data-translate="tran-11">{translate?.["We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email."]??"We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email."}</p>
                    <p data-translate="tran-12">{translate?.["If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111."]??"If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111."}</p>
                    <p data-translate="tran-13">{translate?.["Double-check the email and try entering the OTP again."]??"Double-check the email and try entering the OTP again."}</p>
                    <p><strong data-translate="tran-14">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Double-check the email and try entering the OTP again."}</strong></p>
              </div>
              }

              <div className="ms-auto">
                <VerifyButton btnName="Verify" onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
          {/* <!-- /.col-lg-6 --> */}
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </div>
    }

    </>
  );
};

export default MidEmailOTP;
