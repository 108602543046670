// import React, {
//   useState,
//   useEffect,
//   useRef,
//   useMemo,
//   useCallback,
// } from "react";

// import {
//   Formik,
//   Field,
//   ErrorMessage,
//   Form,
//   FieldArray,
//   useFormikContext,
// } from "formik";

// import * as Yup from "yup";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// // import auth from "../services/authService";
// import Location from "./country";
// // import PDFfile from "../../src/asesst/DRPAgreement.pdf";
// import PDFfile from "../../asset/DRPAgreement.pdf";
// import disputeIcon from "../asset/icons/disputes.svg";
// import agreementsIcon from "../asset/icons/agreements.svg";
// import accountIcon from "../asset/icons/account.svg";
// import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import useHandleModal from "./useModal1";
// import http from "../services/httpService";
// // import { generateOtp } from "../logic/generateOtp";
// // import Navbar2 from "./navbar2";
// import Loading from "./loading";
// import getCurrentDate from "../utils/getCurrentDate";
// import generateRandomInvoiceID from "../utils/generateRandomInvoiceID";
// import titleCase from "../utils/titleCase";


import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Formik,
  Field,
  ErrorMessage,
  Form,
  FieldArray,
} from "formik";

import * as Yup from "yup";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import { PDFDocument, rgb } from "../../pdf-lib";

import fontkit from '@pdf-lib/fontkit';
// import auth from "../services/authService";
import Location from "./country";
// import PDFfile from "../../asesst/DRPAgreement.pdf";
import PDFfile from "../../asset/DRPAgreement.pdf";
import disputeIcon from "../../asset/icons/disputes.svg";
import agreementsIcon from "../../asset/icons/agreements.svg";
import accountIcon from "../../asset/icons/account.svg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import useHandleModal from "../../modal/useModal1";
import http from "../../services/httpService";
import auth from "../../services/authService";

// import { generateOtp } from "../logic/generateOtp";
// import Navbar2 from "./navbar2";
import Loading from "../../utils/loading";
import getCurrentDate from "../../utils/getCurrentDate";
import generateRandomInvoiceID from "../../utils/generateRandomInvoiceID";
import titleCase from "../../utils/titleCase";

const Dispute = (props) => {
  const { handleModal2, handleModal3 } = useHandleModal();
  const formikRef = useRef(null);
  const modalRef = useRef();
  //   const email=auth.getUser().email;
  const email = "pratyush@privatecourt.in";
  const [fee, setFee] = useState(2000);
  const [size, setSize] = useState(2);
  const [dol, setDol] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pansearch, setPanSearch] = useState(Array(size).fill(""));
  const [directors, setDirectors] = useState([]);
  const mid = "6602857170f9ce80dd1f0f3d";
  const [preview, setPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [call, setCall] = useState(false);
  const [showotp, setShowOtp] = useState(false);
  const [disputeID, setDisputeID] = useState("");
  const [otp, setOtp] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [panFetch, setPanFetch] = useState(true);
  const [panArray, setPanArray] = useState([]);
  const [showForm, setShowForm] = useState(Array(size).fill(false));
  const validationSchema2 = Yup.object().shape({
    secondParties: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("name is required"),
        designation: Yup.string(),
        govtId: Yup.string().test(
          "unique-govtId",
          "Government ID must be unique",
          function (value) {
            if (!value) value = "$";
            const { secondParties } = this.from[1].value;
            let arr = secondParties
              ? secondParties.map((party) => {
                  if (party.govtId) return party.govtId;
                  else return "$";
                })
              : [];
            arr = arr.reduce(
              (acc, curr) => (curr !== "$" ? (acc.push(curr), acc) : acc),
              []
            );
            const govtIds = [...arr];
            govtIds.push("$");
            return govtIds.filter((id) => id == value).length == 1;
          }
        ),
        phone: Yup.string()
          .required("Contact is required")
          .test(
            "unique-phone",
            "Phone number must be unique",
            function (value) {
              if (!value) value = "f";
              const { secondParties } = this.from[1].value;
              let arr = secondParties
                ? secondParties.map((party) => {
                    if (!party.phone) return "f";
                    else return party.phone;
                  })
                : [];
              arr = arr.reduce(
                (acc, curr) => (curr !== "f" ? (acc.push(curr), acc) : acc),
                []
              );
              const phones = [...arr];
              phones.push("f");
              return phones.filter((phone) => phone == value).length == 1;
            }
          ),
        email: Yup.string()
          .email("Enter a valid email")
          .required("email is required")
          .test("unique-email", "Email must be unique", function (value) {
            const { secondParties } = this.from[1].value;
            let arr = secondParties
              ? secondParties.map((party) => party.email)
              : [];
            const emails = [...arr];
            if (!value) return false;
            return emails.filter((email) => email == value).length == 1;
          }),
      })
    ),
    Agreement: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      // referenceAgreementNo: Yup.number().required(
      //   "ReferenceAgreementNo is required"
      // ),
    }),
  });

  //separate this object
  const [initialValues, setInitialValues] = useState({
    mid: mid,
    secondParties: [
      {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      },
      {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      },
    ],

    Agreement: {
      valueOfAgreement: "",
      title: "",
      referenceAgreementNo: "",
      DateOfAgreement: getCurrentDate(),
      dateOfCommencement: "",
      Fee: fee,
      phone: "",
      seatofArbitration: {
        country: selectedCountry.name,
        state: selectedState.name,
        city: selectedCity.name,
      },
    },
  });
  const [firstUser, setFirstUser] = useState("");
  const handleEmailChange = useCallback((newEmail) => {
    setFirstUser(newEmail);
  }, []);
  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };
  const toggleOtp = () => {
    setShowOtp((prevShowModal) => !prevShowModal);
  };

  const fetchDetails = async () => {
    try {
      const { data } = await http.post2("/getUsers", {
        id: "6685320b87e94ff21ac561ba",
      });
  
      if (data) {
        setDirectors(data?.mcaFind?.directors);
        setPanFetch(false);
        setFirstUser(data?.panFind?.email);
        setInitialValues((prevValues) => ({
          ...prevValues,
          secondParties: [
            {
              slectionOption: "",
              name: "",
              organization: data?.panFind?.full_name,
              designation: "",
              address: data?.panFind?.address.full,
              govtId: data?.panFind?.pan_number,
              phone: data?.panFind?.phone_number,
              email: data?.panFind?.email,
            },
            ...prevValues.secondParties.slice(1),
          ],
        }));
      } else {
        handleModal2("DATA NOT FOUND");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitOtp = async () => {
    try {
      const res = await http.post2("/esign", {
        otp,
        did: disputeID,
        ip: ipAddress,
        email: firstUser,
      });
  
      // Handle response based on server message
      if (res.data.message === "User not found") {
        handleModal2("User not found. Please check your details.");
      } else if (res.data.message === "OTP is incorrect") {
        handleModal2("Incorrect OTP entered!");
      } else if (res.data.success) {
        handleModal3("Esign successfully added!");
        setShowOtp(false);
        setOtp("");
        setCall(true);
      } else {
        handleModal2("An unexpected error occurred.");
      }
    } catch (err) {
      console.log(err);
      handleModal2("An error occurred while processing your request.");
    }
  };
  const EsignSubmit = async () => {
    const receivers = data.Parties.map((party) => ({ email: party.email }));
    try {
      if (email === receivers[0].email) {
        const [firstReceiver, ...remainingReceivers] = receivers;  
        // Send OTP email
      const otpResponse = await http.post2("/disputepaperEmail", {
        receivers: [firstReceiver],
        type: "otp",
        did: disputeID,
      });
      if (otpResponse.status === 200) {
        handleModal3('OTP has sent successfully in the Email!');
        setShowOtp(true);
        // Send link email after a delay
        setTimeout(async () => {
          try {
            const linkResponse = await http.post2("/disputepaperEmail", {
              receivers: remainingReceivers,
              type: "link",
              did: disputeID,
            });

            if (linkResponse.status === 200) {
              handleModal3('Link email sent successfully!');
            } else {
              handleModal2('Failed to send link email. Please try again.');
            }
          } catch (linkError) {
            console.error('Error sending link email:', linkError);
            handleModal2('Failed to send link email. Please check the console for details.');
          }
        }, 3000);
      } else {
        alert('Failed to send OTP email. Please try again.');
      }
        setTimeout(async () => {
          await http.post2("/disputepaperEmail", {
            receivers: remainingReceivers,
            type: "link",
            did: disputeID,
          });
        }, 3000);
      } else {
        try {
          const response = await http.post2("/disputepaperEmail", {
            receivers,
            type: "link",
            did: disputeID,
          });
          if (response.status === 200) {
            handleModal3('Email sent successfully!');
          } else {
            handleModal2('Something went wrong. Please try again.');
          }
        } catch (error) {
          console.error('Error sending email:', error);
          handleModal2('Failed to send email. Please check the console for details.');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  const Alert = (val) => {
    Swal.fire({
      icon: val === "pan" ? "error" : "warning",
      title: val === "pan" ? "PAN VERIFICATION FAILED" : "Oops...",
      text:
        val === "pan"
          ? "The entered PAN number does not exist."
          : "Incomplete form fill the required field!",
    });
  };

  //Country city and state selection form and dynamic change the value
  const handleSelectChange = (country, state, city) => {
    setSelectedCountry(country);
    setSelectedState(state);
    setSelectedCity(city);
    const updatedInitialValues = { ...initialValues };
    updatedInitialValues.Agreement.seatofArbitration.city = city.name;
    updatedInitialValues.Agreement.seatofArbitration.country = country.name;
    updatedInitialValues.Agreement.seatofArbitration.state = state.name;
    setInitialValues(updatedInitialValues);
  };
  const calculateAndDisplayFee = (formikValues) => {
    const valueOfAgreement = formikValues.Agreement.valueOfAgreement;
    let calculatedFee = 0;
    if (valueOfAgreement === "") {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 2000).toFixed(2) : 2000;
    } else if (valueOfAgreement === 0) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 2000).toFixed(2) : 2000;
    } else if (valueOfAgreement <= 100000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 500).toFixed(2) : 500;
    } else if (valueOfAgreement <= 1000000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 1000).toFixed(2) : 1000;
    } else if (valueOfAgreement <= 5000000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 2000).toFixed(2) : 2000;
    } else if (valueOfAgreement <= 10000000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 5000).toFixed(2) : 5000;
    } else {
      calculatedFee = Math.ceil(valueOfAgreement * 0.001).toFixed(2);
    }
    setFee(calculatedFee);
  };
  const currencyEx = async() => {
    await http.post2("/currsave")
      .then((res) => {
        setDol(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const generateCustomObjectId = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const random = Math.random().toString(16).substr(2, 10);
    let counter = Math.floor(Math.random() * 16777216).toString(16);
    counter = counter.padStart(6, "0");
    const customObjectId = timestamp + random + counter;
    return customObjectId;
  };

  // Fetching Pan details and Adding automatic forms
  const handleVerifyClick = async(index, setFieldValue) => {
    // Set the showForm state for the clicked index to true
    setIsLoading(true);
    await http.post2("/getpans", { panno: pansearch[index] })
      .then((res) => {
        // setFieldValue(`secondParties.${index}.name`, res.data.);
        console.log(res.data);
        if (res.data == "Pan Number is incorrect") {
          setIsLoading(false);
          Alert("pan");
        } else if (res.data.category === "person") {
          setFieldValue(`secondParties.${index}.name`, res.data.full_name);
          setFieldValue(
            `secondParties.${index}.address`,
            res.data.address.full
          );
          setPanArray((prevState) => ({
            ...prevState,
            [pansearch[index]]: res.data?.category,
          }));
          setFieldValue(`secondParties.${index}.email`, res.data.email);
          setFieldValue(
            `secondParties.${index}.phone`,
            res.data.phone_number ? res.data.phone_number : ""
          );
          setFieldValue(`secondParties.${index}.govtId`, res.data.pan_number);
          setIsLoading(false);
          setShowForm((prev) => {
            const newShowForm = [...prev];
            newShowForm[index] = true;
            return newShowForm;
          });
        } else {
          setPanArray((prevState) => ({
            ...prevState,
            [pansearch[index]]: res.data?.category,
          }));
          setFieldValue(
            `secondParties.${index}.organization`,
            res.data.full_name
          );
          setFieldValue(
            `secondParties.${index}.address`,
            res.data.address.full
          );
          setFieldValue(`secondParties.${index}.email`, res.data.email);
          setFieldValue(`secondParties.${index}.phone`, res.data.phone_number);
          setFieldValue(`secondParties.${index}.govtId`, res.data.pan_number);
          setIsLoading(false);
          setShowForm((prev) => {
            const newShowForm = [...prev];
            newShowForm[index] = true;
            return newShowForm;
          });
        }
        //This is for setting true to show Form Field
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // creation of pdf through dynamic form fillings
  const generateAndDownloadPDF1 = async (formikValues, preview) => {
    // console.log(formikValues.Parties);
    const existingPdfBytes = await fetch(PDFfile).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const FormData1 = [
      { text: titleCase(String(formikValues?.Parties[0]?.name)), y: 575 },
      {
        text: titleCase(String(formikValues?.Parties[0]?.organization)),
        y: 535,
      },
      { text: formikValues.Parties[0]?.email, y: 495 },
      { text: titleCase(String(formikValues?.Parties[0]?.phone)), y: 455 },
      { text: formikValues.Parties[0]?.govtId, y: 415 },
    ];

    const FormData2 = [
      { text: titleCase(String(formikValues?.Parties[1]?.name)), y: 575 },
      {
        text: titleCase(String(formikValues?.Parties[1]?.organization)),
        y: 535,
      },
      { text: formikValues?.Parties[1]?.email, y: 495 },
      { text: titleCase(String(formikValues?.Parties[1]?.phone)), y: 455 },
      { text: formikValues?.Parties[1]?.govtId, y: 415 },
    ];
    if (
      preview === "open" ||
      (formikValues?.Agreement.title !== "" &&
        formikValues?.Parties[0].name !== "" &&
        formikValues?.Parties[0].email !== "" &&
        formikValues?.Parties[1].name !== "" &&
        formikValues?.Parties[1].email !== "")
    ) {
      try {
        FormData1.map(({ text, y }) => {
          const textString = typeof text === "number" ? text.toString() : text;
          firstPage.drawText(textString ? textString : "N/A", {
            x: 110,
            y,
            size: 10,
            color: rgb(0, 0, 0),
          });
        });

        if (data.Parties[0]?.ip && data.Parties[0]?.date) {
          firstPage.drawText(`${data.Parties[0]?.name}`, {
            x: 110,
            y: 385,
            size: 6,
            color: rgb(0, 0, 0),
          });
          // Assuming data.Parties[0].date is a valid date string
          var dateObj = new Date(data.Parties[0].date);
          firstPage.drawText(`${dateObj}`, {
            x: 110,
            y: 375,
            size: 6,
            color: rgb(0, 0, 0),
          });
          firstPage.drawText(`${data.Parties[0].ip}`, {
            x: 110,
            y: 365,
            size: 6,
            color: rgb(0, 0, 0),
          });
        }
        FormData2.map(({ text, y }) => {
          const textString = typeof text === "number" ? text.toString() : text;
          firstPage.drawText(textString ? textString : "N/A", {
            x: 360,
            y,
            size: 10,
            color: rgb(0, 0, 0),
            color: rgb(0, 0, 0),
          });
        });
        if (data.Parties[1]?.ip && data.Parties[1]?.date) {
          firstPage.drawText(`${data.Parties[1]?.name}`, {
            x: 360,
            y: 385,
            size: 6,
            color: rgb(0, 0, 0),
          });
          // Assuming data.Parties[0].date is a valid date string
          var dateObj = new Date(data.Parties[0].date);
          firstPage.drawText(`${dateObj}`, {
            x: 360,
            y: 375,
            size: 6,
            color: rgb(0, 0, 0),
          });
          firstPage.drawText(`${data.Parties[1].ip}`, {
            x: 360,
            y: 365,
            size: 6,
            color: rgb(0, 0, 0),
          });
        }
        firstPage.drawText(
          `${formikValues.AgreementData[0]?.referenceAgreementNo}`,
          {
            x: 110,
            y: 678,
            size: 12,
            color: rgb(0, 0, 0),
          }
        );
        firstPage.drawText(
          `${
            formikValues.AgreementData[0]?.seatofArbitration.country
              ? formikValues.AgreementData[0]?.seatofArbitration.country ===
                "India"
                ? "Rs"
                : "USD"
              : "USD"
          } ${
            formikValues.AgreementData[0]?.seatofArbitration.country === "India"
              ? formikValues.AgreementData[0]?.Fee
              : formikValues.AgreementData[0]?.Fee
          }`,
          {
            x: 350,
            y: 678,
            size: 12,
            color: rgb(0, 0, 0),
          }
        );
        firstPage.drawText(
          `${formikValues.AgreementData[0]?.DateOfAgreement}`,
          {
            x: 470,
            y: 678,
            size: 12,
            color: rgb(0, 0, 0),
          }
        );
        firstPage.drawText(
          `${
            formikValues.AgreementData[0]?.seatofArbitration.state
              ? formikValues.AgreementData[0]?.seatofArbitration.state
              : ""
          }`,
          {
            x: 450,
            y: 110,
            size: 12,
            color: rgb(0, 0, 0),
          }
        );
        firstPage.drawText(
          `Title : ${formikValues.AgreementData[0]?.title} & Value : ${
            formikValues.AgreementData[0]?.valueOfAgreement
              ? formikValues.AgreementData[0]?.valueOfAgreement
              : 0
          }`,
          {
            x: 50,
            y: 328,
            size: 12,
          }
        );

        if (formikValues.Parties.length > 2) {
          //new page added
          let thirdParty = pdfDoc.addPage([612, 792]);
          thirdParty.drawText("Other Parties Details:", {
            x: 30,
            y: 750,
            size: 20,
          });
          thirdParty.drawLine({
            start: { x: 30, y: 740 },
            end: { x: 570, y: 740 },
            thickness: 1,
          });
          let yOffset = 740;
          const addTextToPage = (page, text, x, y, fontSize, count, font) => {
            const lineHeight = fontSize + 2;
            if (yOffset <= 10) {
              // Create a new page if remaining space is not enough
              page = pdfDoc.addPage([612, 792]);
              yOffset = 740; // Start at the top of the new page
            }
            page.drawText(text ? text : "N/A", {
              x,
              y: yOffset,
              size: fontSize,
            });
            if (count === 3) {
              yOffset -= lineHeight; // Move down for the next content, considering font size
            }
            return page;
          };
          formikValues?.Parties.slice(2).forEach((secondParty, index) => {
            yOffset -= 30; // Add some space between parties
            thirdParty = addTextToPage(
              thirdParty,
              `Party ${index + 3} Detail`,
              50,
              yOffset,
              15
            );
            yOffset -= 10;
            for (const [key, value] of Object.entries(secondParty)) {
              if (key !== "slectionOption") {
                const keyText = key !== "govtId" ? titleCase(key) : "Govt ID";
                const valueText = `${value}`;
                // Set x-coordinates for key and value
                const keyX = 70;
                const separatorX = 130; // Adjust this value based on your layout requirements
                const valueX = 150; // Adjust this value based on your layout requirements
                yOffset -= 15;
                // Add key text to page
                thirdParty = addTextToPage(
                  thirdParty,
                  keyText,
                  keyX,
                  yOffset,
                  10
                );
                // Add separator text to page
                thirdParty = addTextToPage(
                  thirdParty,
                  ":",
                  separatorX,
                  yOffset,
                  10
                );
                // Add value text to page
                thirdParty = addTextToPage(
                  thirdParty,
                  valueText,
                  valueX,
                  yOffset,
                  8,
                  3
                );
              }
            }
            yOffset -= 30;
            thirdParty = addTextToPage(
              thirdParty,
              `Signature`,
              70,
              yOffset,
              10
            );
            thirdParty = addTextToPage(thirdParty, ` : `, 130, yOffset, 10);

            thirdParty = addTextToPage(
              thirdParty,
              `_______________`,
              150,
              yOffset,
              10,
              3
            );
            if (
              formikValues.Parties[index + 2]?.ip &&
              formikValues.Parties[index + 2]?.date
            ) {
              // console.log(index)
              yOffset += 35;
              thirdParty = addTextToPage(
                thirdParty,
                `${formikValues.Parties[index + 2].ip}`,
                160,
                yOffset,
                5
              );
              yOffset -= 10;
              thirdParty = addTextToPage(
                thirdParty,
                `${formikValues.Parties[index + 2].date} `,
                160,
                yOffset,
                5
              );
              yOffset -= 10;
              thirdParty = addTextToPage(
                thirdParty,
                `${formikValues.Parties[index + 2].name} `,
                160,
                yOffset,
                5
              );
            }
          });
        }
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        if (preview === "open") {
          const iframe = document.getElementById("pdfIframe");
          console.log(iframe);
          if (iframe) {
            iframe.src = url; // Set the src only if the iframe is found
          }
          // iframe.src = url;
          // setShowModal(true);
        } else {
          // Initiate the download
          const link = document.createElement("a");
          link.href = url;
          link.download = "DisputeResolutionPaper.pdf";
          link.click();
          setShowModal(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      Alert();
    }
  };

  //Final submission of form data to database
  const handleSubmit = async(values, actions) => {
    calculateAndDisplayFee(values);
    values.Agreement.Fee = fee;
    values.Agreement.referenceAgreementNo = generateRandomInvoiceID();
    const currentDate = new Date();
    const readableDate = currentDate.toLocaleDateString();
    const disputeid = generateCustomObjectId();
    console.log(disputeid);
    setDisputeID(disputeid);
    values.Agreement.DateOfAgreement = readableDate;
    if (
      values.Agreement.title !== "" &&
      values.secondParties[0].name !== "" &&
      values.secondParties[0].email !== "" &&
      values.secondParties[1].name !== "" &&
      values.secondParties[1].email !== ""
    ) {
      try {
        await http.post2("/disputepaper", {
            mid: mid,
            values: values,
            did: disputeid,
          })
          .then((res) => {
            console.log(res.data);
            if (res.data === "Data Added Successfully") {
              
      http.post2("/disputeData", {
                  did: disputeid,
                })
                .then((res) => {
                  setData(res.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            // setActiveEsign(true);
            setPreview(true);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        // console.log("Email and PDF sent successfully");
        // generateAndDownloadPDF(values);
        // const modal = modalRef.current;
        // if (modal) {
        //   const modalInstance = new window.bootstrap.Modal(modal);
        //   modalInstance.show();
        // }
        console.error("Error sending email and PDF:", error);
      }
      actions?.setSubmitting(true);
      // setDisable(true);
    } else {
      Alert("Fill The Agreement Form Completely");
    }
  };

  // Payment for buying dispute paper agreement
  const handlePay = async (values, actions) => {
    // await handleSubmit(values,actions);
    const initializeRazorpay = async () => {
      const data = await http.post2("/orders", {
        amount: 1,
      });
      console.log(data.data);
      initPayment(data.data);
    };
    const initPayment = (data) => {
      const options = {
        key: "rzp_live_MpsNui8coFW1Ft",
        amount: data.data.amount,
        currency: data.data.currency,
        name: "Dispute Resolution Paper",
        description: "Dispute Agreement",
        order_id: data.data.id,
        handler: async (response) => {
          try {
            const vhttpUrl ="/verifypayment";
            const { data } = await http.post2(vhttpUrl, response);
            console.log(data);
            if (data.message === "Payment Verified Successfully") {
              handleSubmit(values, actions);
            } else {
              alert("Payment Failed");
            }
          } catch (err) {
            console.log(err);
          }
        },
        theme: {
          color: "#ff7529",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    };
    await initializeRazorpay();
  };
  const handleOkButton = () => {
    props.history.push("/usersDashboard");
  };
  console.log(data);

  // Fetching dispute
  const fetchDetails1 = async () => {
    await http.post2("/disputeData", {
        did: disputeID,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if(firstUser==""){
      fetchDetails();
      currencyEx();
      const fetchIpAddress = async () => {
        try {
          axios
            .get("https://api.ipify.org/?format=json")
            .then((res) => {
              setIpAddress(res.data.ip);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.error("Error fetching IP address:", error);
        }
      };
      fetchIpAddress();
    }
    //IP address Fetching


    setShowForm((prevShowForm) => {
      const newShowForm = [...prevShowForm];
      while (newShowForm.length < size) {
        newShowForm.push(false);
      }
      while (newShowForm.length > size) {
        newShowForm.pop();
      }
      return newShowForm;
    });
    if (call) {
      fetchDetails1();
    }
  }, [size, call]); //i remove intialvalues from here
  return (
    <>
      {isLoading && <Loading />}
      {panFetch && <Loading />}
      <div className="main-wrapper">
        <div className="container-fluid">
          <div
            className="modal fade"
            id="updates-1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            ref={modalRef}
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  {/* <div className="text-center"> */}
                  <h1
                    className="modal-title fs-5 text-center"
                    id="exampleModalLabel"
                  >
                    Agreement created successfully
                  </h1>
                  {/* </div> */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => props.history.push("/usersDashboard")}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3">
              <div className="left-wrapper">
                {/* <Navbar2 /> */}
                {/* <h2 className="admin-name">{companyName?companyName:email}</h2> */}
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>

                <h3 className="small-des">
                  You are in the agreement section. Here you can download
                  dispute resolution agreement.
                </h3>
                {/* <button
                  className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                  type="button"
                  // data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Perform Tasks
                </button> */}
                <div className="" id="collapseExample">
                  <div className="dot-bg">
                    <h2 className="d-lg-block d-none">Perform Tasks</h2>
                    <div className="row row-cols-2">
                      <div className="col">
                        <Link to={"/usersDashboard"} className="white-circle">
                          <img
                            src={agreementsIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Agreement List</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Create New</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/disputes" className="white-circle">
                          <img
                            src={disputeIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Dispute</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Account</h2>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.col-lg-12 --> */}
            <div className="col-xl-9">
              <div className="right-wrapper">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema2}
                  onSubmit={handlePay}
                  innerRef={formikRef}
                  // onSubmit={async (values) => {
                  //   alert(JSON.stringify(values, null, 500));
                  // }}
                >
                  {({
                    setFieldValue,
                    isSubmitting,
                    isValidating,
                    isValid,
                    values,
                    actions,
                  }) => (
                    <Form>
                      <div className="party-form">
                        <div className="row mb-3">
                          {/* <!-- /.col-lg-4 -->   */}
                          <FieldArray name="secondParties">
                            {({ push, remove }) => (
                              <>
                                <div className="col-lg-12 text-end mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      push({
                                        slectionOption: "",
                                        name: "",
                                        organization: "",
                                        designation: "",
                                        address: "",
                                        govtId: "",
                                        phone: "",
                                        email: "",
                                      });
                                      setSize((prevSize) => prevSize + 1);
                                    }}
                                  >
                                    <i className="bi bi-plus-lg text-primary"></i>
                                    Add More Party
                                  </button>
                                </div>
                                {values.secondParties.map((party, index) => (
                                  <div className="col-lg-4">
                                    <div className="card card-new">
                                      {index > 1 && (
                                        <button
                                          type="button"
                                          className="btn-close"
                                          aria-label="Close"
                                          onClick={() => {
                                            remove(index);
                                            setSize((prevSize) => prevSize - 1);
                                            // Remove the last element from the pansearch array
                                            setPanSearch((prevPansearch) => {
                                              const newPansearch = [
                                                ...prevPansearch,
                                              ];
                                              newPansearch.pop();
                                              return newPansearch;
                                            });
                                          }}
                                        >
                                          <i className="bi bi-x-lg"></i>
                                        </button>
                                      )}
                                      <h2>{index + 1}. Party </h2>
                                      {/* This is for GST or PAN Verification */}
                                      {index > 0 &&
                                        showForm[index] === false && (
                                          <div>
                                            <label className="form-label form-label-new">
                                              Business PAN
                                            </label>
                                            <input
                                              className="form-control form-control-new"
                                              placeholder="Enter your Pan"
                                              value={pansearch[index]}
                                              onChange={(e) => {
                                                const newPansearch = [
                                                  ...pansearch,
                                                ];
                                                newPansearch[index] =
                                                  e.target.value.toUpperCase();
                                                setPanSearch(newPansearch);
                                              }}
                                            />
                                            <button
                                              className="btn btn-primary mt-4"
                                              onClick={() =>
                                                handleVerifyClick(
                                                  index,
                                                  setFieldValue
                                                )
                                              }
                                            >
                                              Verify
                                            </button>
                                          </div>
                                        )}

                                      {showForm[index] || index == 0 ? (
                                        <>
                                          {index === 0 ? (
                                            <div className="mb-2">
                                              <label className="form-label form-label-new">
                                                Name
                                              </label>
                                              {directors?.length > 0 ? (
                                                <Field
                                                  as="select"
                                                  className="form-select mb-3"
                                                  name={`secondParties.${index}.name`}
                                                  aria-label="Default select example"
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `secondParties.${index}.name`,
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value={""}>
                                                    Default Party
                                                  </option>
                                                  {directors?.map(
                                                    (item, idx) => (
                                                      <option
                                                        key={idx}
                                                        value={item.name}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    )
                                                  )}
                                                </Field>
                                              ) : (
                                                <div className="mb-2">
                                                  <Field
                                                    type="text"
                                                    className="form-control form-control-new"
                                                    id={`secondParties.${index}.name`}
                                                    name={`secondParties.${index}.name`}
                                                    placeholder="Add Signatory"
                                                  />
                                                  <ErrorMessage
                                                    name={`secondParties.${index}.name`}
                                                    component="div"
                                                    className="error-message text-danger"
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="mb-2">
                                              <label
                                                htmlFor={`secondParties.${index}.name`}
                                                className="form-label form-label-new"
                                              >
                                                Name
                                              </label>
                                              <Field
                                                type="text"
                                                className="form-control form-control-new"
                                                id={`secondParties.${index}.name`}
                                                name={`secondParties.${index}.name`}
                                                placeholder="Enter Name"
                                              />
                                              <ErrorMessage
                                                name={`secondParties.${index}.name`}
                                                component="div"
                                                className="error-message text-danger"
                                              />
                                            </div>
                                          )}

                                          {}
                                          <div className="mb-2">
                                            <label
                                              htmlFor={`secondParties.${index}.organization`}
                                              className="form-label form-label-new"
                                            >
                                              Organization / Person Name
                                            </label>
                                            <Field
                                              type="text"
                                              disabled={
                                                index == 0
                                                  ? true
                                                  : false ||
                                                    values.secondParties[index]
                                                      .organization === ""
                                              }
                                              className="form-control form-control-new"
                                              id={`secondParties.${index}.organization`}
                                              name={`secondParties.${index}.organization`}
                                              placeholder="Enter Organization"
                                            />
                                            <ErrorMessage
                                              name={`secondParties.${index}.organization`}
                                              component="div"
                                              className="error-message text-danger"
                                            />
                                          </div>

                                          <div className="mb-2">
                                            <label
                                              htmlFor={`secondParties.${index}.phone`}
                                              className="form-label form-label-new"
                                            >
                                              Phone
                                            </label>
                                            <Field
                                              type="number"
                                              // disabled={
                                              //   index == 0 ? true : false
                                              // }
                                              className="form-control form-control-new"
                                              id={`secondParties.${index}.phone`}
                                              name={`secondParties.${index}.phone`}
                                              placeholder="Enter Phone"
                                            />
                                            <ErrorMessage
                                              name={`secondParties.${index}.phone`}
                                              component="div"
                                              className="error-message text-danger"
                                            />
                                          </div>
                                          <div className="mb-2">
                                            <label
                                              htmlFor={`secondParties.${index}.email`}
                                              className="form-label form-label-new"
                                            >
                                              Email
                                            </label>
                                            <Field
                                              type="email"
                                              // disabled={index==0 ? true : false}
                                              className="form-control form-control-new"
                                              id={`secondParties.${index}.email`}
                                              name={`secondParties.${index}.email`}
                                              placeholder="Enter email"
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `secondParties.${index}.email`,
                                                  e.target.value
                                                );
                                                if (index === 0) {
                                                  handleEmailChange(e.target.value);
                                                }
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`secondParties.${index}.email`}
                                              component="div"
                                              className="error-message text-danger"
                                            />
                                          </div>
                                          <div className="mb-2">
                                            <label
                                              htmlFor={`secondParties.${index}.govtId`}
                                              className="form-label form-label-new"
                                            >
                                              Government ID /GST No.
                                            </label>
                                            {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder="4561238"
                                  /> */}
                                            <Field
                                              type="text"
                                              disabled={
                                                index == 0 ? true : false
                                              }
                                              className="form-control form-control-new"
                                              id={`secondParties.${index}.govtId`}
                                              name={`secondParties.${index}.govtId`}
                                              placeholder="Enter ID"
                                              onChange={(e) => {
                                                const input = e.target.value;
                                                const alphanumericInput = input
                                                  .replace(/[^A-Za-z0-9]/g, "")
                                                  .toUpperCase();
                                                setFieldValue(
                                                  `secondParties.${index}.govtId`,
                                                  alphanumericInput
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`secondParties.${index}.govtId`}
                                              component="div"
                                              className="error-message text-danger"
                                            />
                                          </div>
                                          <div className="mb-2">
                                            <label
                                              htmlFor={`secondParties.${index}.designation`}
                                              className="form-label form-label-new"
                                            >
                                              Designation
                                            </label>
                                            {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder="Manager"
                                  /> */}
                                            <Field
                                              type="text"
                                              className="form-control form-control-new"
                                              id={`secondParties.${index}.designation`}
                                              name={`secondParties.${index}.designation`}
                                              placeholder="Enter designation"
                                            />
                                          </div>
                                          <div className="mb-2">
                                            <label
                                              htmlFor={`secondParties.${index}.address`}
                                              className="form-label form-label-new"
                                            >
                                              Address
                                            </label>
                                            <Field
                                              as="textarea"
                                              className="form-control"
                                              id={`secondParties.${index}.address`}
                                              name={`secondParties.${index}.address`}
                                              rows="4"
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </FieldArray>
                        </div>
                        {/* <!-- /.row --> */}
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card card-new">
                              <h2>Important Information Details</h2>
                              <div className="row">
                                <div className="row">
                                  <label
                                    htmlFor="Agreement.seatofArbitration"
                                    className="form-label form-label-new"
                                  >
                                    Seat of Arbitration
                                  </label>
                                  <Location
                                    handleSelectChange={handleSelectChange}
                                  />
                                </div>
                              </div>
                              {/* <form action="#" className="row"> */}
                              {/* <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.seatofArbitration"
                                    className="form-label form-label-new"
                                  >
                                    Seat of Arbitration
                                  </label> */}
                              {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder="Enter Seat"
                                    id="Agreement.seatofArbitration"
                                    name="Agreement.seatofArbitration"
                                  /> */}
                              {/* <Location handleSelectChange={handleSelectChange}/>
                                </div> */}
                              <div className="row">
                                <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.valueOfAgreement"
                                    className="form-label form-label-new"
                                  >
                                    Value of Agreement
                                  </label>
                                  <Field
                                    type="number"
                                    className="form-control form-control-new"
                                    onClick={calculateAndDisplayFee(values)}
                                    id="Agreement.valueOfAgreement"
                                    name="Agreement.valueOfAgreement"
                                  />
                                  <ErrorMessage
                                    name="Agreement.valueOfAgreement"
                                    component="div"
                                    className="error-message text-danger"
                                  />
                                  {fee > 0 ? (
                                    <p className="text-primary">
                                      Dispute resolution agreement fee {""}
                                      {values.Agreement.seatofArbitration
                                        .country
                                        ? values.Agreement.seatofArbitration
                                            .country === "India"
                                          ? "Rs"
                                          : "USD"
                                        : "USD"}
                                      . {fee}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.title"
                                    className="form-label form-label-new"
                                  >
                                    Title of Agreement
                                  </label>
                                  {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder=""
                                  /> */}
                                  <Field
                                    type="text"
                                    className="form-control form-control-new"
                                    id="Agreement.title"
                                    name="Agreement.title"
                                    placeholder="Enter Title"
                                  />
                                  <ErrorMessage
                                    name="Agreement.title"
                                    component="div"
                                    className="error-message text-danger"
                                  />
                                </div>

                                <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.dateOfCommencement"
                                    className="form-label form-label-new"
                                  >
                                    Date of Commencement Agreement
                                  </label>
                                  <Field
                                    type="date"
                                    className="form-control form-control-new"
                                    id="Agreement.dateOfCommencement"
                                    name="Agreement.dateOfCommencement"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                              {/* </form> */}
                              {/* {fee > 0 ? <p className="text-primary">Dispute resolution agreement fee Rs. {fee}</p> : ""} */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container mb-3 mt-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting || isValidating || !isValid}
                          // onClick={()=>{generateAndDownloadPDF1(values)}}
                          onClick={() => {
                            handlePay(values, actions);
                          }}
                        >
                          Proceed To Pay
                        </button>
                        <button
                          type="button"
                          disabled={!preview}
                          onClick={() => {
                            if (typeof toggleModal === "function") {
                              toggleModal();
                            }
                            generateAndDownloadPDF1(data, "open");
                          }}
                          className="btn btn-primary mx-4"
                        >
                          Preview
                        </button>
                      </div>
                      {/* <!-- /.party-form --> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div>
              {showModal && (
                <div
                  className="modal show fade d-block"
                  // tabindex="-1"
                  role="dialog"
                  style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                >
                  <div
                    class="modal-dialog modal-lg"
                    role="document"
                  >
                    <div className="modal-content custom-size">
                      <div className="modal-header">
                        <h5 className="modal-title">More Details Overview</h5>
                        <button
                          type="button"
                          className="close"
                          onClick={toggleModal}
                          
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      {/* <div className="modal-body"> */}
                        <div className="d-flex flex-column align-items-center">
                          <iframe
                            id="pdfIframe"
                            title="Generated PDF"
                            width="100%"
                            height="500px"
                          ></iframe>
                          <button
                            className="btn btn-primary mt-3"
                            onClick={EsignSubmit}
                          >
                            Esign
                          </button>
                        </div>
                      {/* </div> */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showotp && (
                <div class="modal show fade d-block" role="dialog">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">More Details Overview</h5>
                        <button type="button" className="close">
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="otp" className="form-label">
                            OTP:
                          </label>
                          <input
                            className="form-control-new p-3"
                            type="number"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                          />
                          <button
                            onClick={submitOtp}
                            className="btn btn-primary mx-2 mt-4"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleOtp}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {showPay && (
                <div class="modal show" tabindex="-1" role="dialog">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-body">
                        
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dispute;
 